<template>
  <div class="page page--bidaskbit">
    <section class="section" id="sec-1" data-sec="1">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-lg-6">
            <div class="wrapper d-flex flex-column justify-content-center ps-xl-4 pe-xl-5">
              <p class="text tc-blue fs-xl-20 fw-500 ta-center ta-md-left mb-3 mb-md-3">{{ cc.sec1.text[0] }}</p>
              <h1 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center ta-md-left mb-3 mb-md-4"
                  :class="[{'pe-xl-5': ($i18n.locale !== 'ru' && $i18n.locale !== 'cs')}]">
                {{ cc.sec1.title }}
              </h1>
              <p class="text tc-yellow fs-22 fs-sm-26 fs-lg-30 fw-500 ta-center ta-md-left mb-3 mb-md-4">{{ cc.sec1.text[1] }}</p>
              <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-4 mb-md-5 px-3 px-sm-0">{{ cc.sec1.text[2] }}</p>
            </div>
          </div>
          <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0">
            <div class="img img--bidaskbit-sec1">
              <img src="/img/pages/bidaskbit/sec1/img-1.png" alt="About">
            </div>
          </div>
        </div>
        <div class="row mb-5 pb-lg-5">
          <div class="col-12 col-lg-6" v-for="(item, i) in cc.sec1.blocks" :key="'item-bab-' + i" :class="{ 'mb-3 mb-lg-0': i === 0 }">
            <div class="block" :class="'block--bab-' + (i + 1)">
              <p class="text tc-white fs-18 fs-sm-20 fw-400 lh-md-17 ta-center mb-1">{{ item.title }}</p>
              <p class="text tc-white fs-22 fs-sm-26 fw-600 lh-md-17 ta-center">{{ item.text }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <a :href="cc.sec1.btn.to" target="_blank" class="btn btn-lg btn-3-blue btn-w-200 btn-h-66 mx-auto"><span>{{ cc.sec1.btn.text }}</span></a>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <comp-sample :title="cc.sec2.title" :text="cc.sec2.text" :img="'/img/pages/bidaskbit/sec2/img-1.png'" :imgmob="'/img/pages/bidaskbit/sec2/mob/img-1.png'" :isAbstract="false" :theme="'blue'"/>
    </section>
    <section class="section section--gray" id="sec-3" data-sec="3">
      <div class="container">
        <div class="row mb-4 mb-md-5">
          <div class="col-12">
            <div class="img img--bidaskbit-sec3 mb-3 mb-md-4">
              <img src="/img/pages/bidaskbit/sec3/img-1.svg" alt="bid ask bit" class="mx-auto">
            </div>
            <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center">{{ cc.sec3.title }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 mb-4 px-4 px-md-3" v-for="(item, i) in cc.sec3.boxes" :key="'benefit-' + i">
                <div class="block block--benefits">
                  <div class="ico ico--partners mb-3 mb-lg-4" :class="'ico--partners-' + item.theme">
                    <img :src="'/img/icons/ico-' + item.key + '-' + item.theme + '.svg'" :alt="item.key">
                  </div>
                  <h2 class="title fs-18 fs-md-20 fw-600 mb-2 mb-lg-3">{{ item.title }}</h2>
                  <p class="text tc-silver fs-14 fs-sm-16 lh-20">{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompSample from '@/components/Sample'
export default {
  components: {
    CompSample
  },
  computed: {
    cc () {
      return this.$t('pages.bidaskbit')
    }
  },
  beforeCreate () {
    const list = this.$store.state.blackList
    const country = this.$store.state.ipCountry
    if (list.includes(country)) this.$router.push(`/${this.$i18n.locale}/`)
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
